#flags{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px;
}
.flags__item {
    margin-bottom: 10px;
    margin: 10px;
    cursor: pointer;
}
.flags__item img{
    width: 30px;
    transition: all .3s;
    box-shadow: 3px 3px 5px #000000;
}
.flags__item img:hover{
    scale: 1.2;
    transition: all .3s;
}
.logo{
    position: absolute;
}
.logo_pisouno{
    display: flex;
    position: absolute;
    margin: 20px;
    z-index: 100;
}