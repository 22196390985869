.contenidoBar_principal {
    display: flex;
    margin: 5rem;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
}
.parrafo_tips_bar {
    background-image: linear-gradient(#00000098, #0000008e),url('../image/alcohol-1961542_1280-min.jpg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    box-shadow: 1px 1px 10px #000;
    color: var(--p_color);
    padding: 2rem;
    justify-content: left;
    text-align: left;
}
.parrafo_tips_bar li{
    list-style: disc;
}
.parrafo_tips_bar ul, li, p{
    font-size: .9rem;
}
.tips_bartender_h2 {
    font-size: 1.8rem;
    color: var(--btn_color);
}
h3 {
    font-family: "Caveat", cursive;
    font-size: 1.5rem;
    margin-top: 1rem;
    color: var(--btn_color);
}
h2{
    color: var(--second_color);
    font-family: 'Caveat', cursive;
    text-align: left;
    font-size: 1.7rem;
    margin-bottom: 1rem;
}
.img_tips_bar {
    width: 500px;
    box-shadow: 1px 1px 10px #000;
}
@media (max-width: 970px) {
.contenidoBar_principal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.parrafo_tips_bar {
    background-position: right;
    order: 1;
    width: 320px;
    text-align: left;
    border: none;
}
.img_tips_bar {
    max-width: 320px;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
}
}
