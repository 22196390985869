@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&family=Montserrat:wght@200;300;400;500;600&display=swap');

:root{
    --main_color:#dddddd;
    --second_color:#242424;
    --btn_color:#dfab03;
    --p_color:#ffffff;
    --p_second_color:#222222;
}
*{
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    margin: 0;
}
body{
    background-color: var(--main_color);
}
p{
    font-weight: 300;
}
.container{
    max-width: 1200px;
    margin:  0 auto;
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: var(--second_color);
    border-radius: 5px;
}
::-webkit-scrollbar-track {
    background: none;
}
.contenido-principal{
    display: flex;
    justify-content: space-evenly;
    padding: 30px;
    flex-wrap: wrap;
}
.contenido{
    box-shadow: 1px 1px 10px #000;
    background-color: var(--second_color);
}
.contenido-principal img{
    margin-bottom: 1rem;
    max-width: 400px;
}
.contenido-principal h2{
    color: var(--p_color);
    font-family: 'Caveat', cursive;
    text-align: center;
    font-size: 1.7rem;
    margin-bottom: .1rem;
}
.contenido span{
    color: var(--p_second_color);
    font-weight: 600;
    padding: 1rem;
    font-size: .8rem;
    background-color: #dfab03;
    border-radius: 0px 100px 100px 0px;
}
.contenido-principal p{
    max-width: 400px;
    font-weight: 300;
    color: var(--p_color);
    padding: 1rem;
    padding-bottom: 1.5rem;
    padding-top: 1.8rem;
}
@media(max-width:600px){
    .contenido-principal{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .contenido{
        margin-bottom: 1.5rem;
    }
    .contenido-principal p{
        max-width: 320px;
        margin-bottom: 1.5rem;
    }
    .contenido-principal img{
        max-width: 320px;
    }
    .contenido-principal h2{
        font-size: 1.9rem;
        margin-bottom: 1rem;
    }
}