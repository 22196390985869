.header {
    background-image: linear-gradient(#000000ad, #0000009f), url('../image/Bg_pisouno.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
    align-items: center;
    display: flex;
}

.header-content h1 {
    color: var(--p_color);
    border-top: .1rem solid var(--btn_color);
    border-bottom: .1rem solid var(--btn_color);
    padding: .5rem;
    font-size: 1.7rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
    margin-bottom: 1rem;
}
.header-content {
    display: flex;
    justify-items: center;
    flex-direction: column;
}

.header-content span {
    color: var(--p_color);
    text-transform: uppercase;
    font-size: 1.1rem;
    letter-spacing: .5rem;
    margin-bottom: .5rem;
    text-align: center;
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: var(--second_color);
    box-shadow: 0 3px 10px #000000;
}

.btns_navbar {
    margin: 10px;
    transition: all .3s;
}
.btns_navbar:hover{
    scale: 1.1;
}
.btn-redes{
    background-color: #131313;
    padding: .3rem;
    border-radius: 10px;
    transition: all .3s;
}
.btn-redes:hover{
    scale: 1.1;
    transition: all .3s;
}
.navbar a {
    text-transform: uppercase;
    text-decoration: underline;
    margin: 5px;
    padding: .5rem;
    color: var(--main_color);
    font-family: 'Caveat', cursive;
    font-weight: 600;
    font-size: 1.2rem;
}
@media (max-width: 600px){
    .navbar a{
        margin: auto;
        font-size: 1.2rem;
    }
    .btns_navbar{
        margin: 5px;
    }
    .header-content span{
        font-size: 80%;
    }
    .header-content h1{
        font-size:1.4rem;
    }
}

