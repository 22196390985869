.footer{
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    justify-items: center;
    background-color: var(--second_color);
}
.footer-content{
    font-size: .9rem;
    padding: 15px;
    color: var(--p_second_color);
    display: flex;
    color: var(--p_color);
}
.footer-content a{
    text-decoration: underline;
    font-family: 'Caveat', cursive;
    font-size: 1.2rem;
}
.footer-content p{
    font-family: 'Caveat', cursive;
    font-size: 1.2rem;
    margin-bottom: .2rem;
    padding-right: 1rem;
    color: var(--p_color);
}
@media(max-width:600px){
    .footer-content{
        display: flex;
        font-size: 1rem;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .footer-content p{
        font-size: 1rem;
    }
}